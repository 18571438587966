@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local(''), url('../fonts/poppins-v15-latin-ext_latin_devanagari-100.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''), url('../fonts/poppins-v15-latin-ext_latin_devanagari-300.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('../fonts/poppins-v15-latin-ext_latin_devanagari-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''), url('../fonts/poppins-v15-latin-ext_latin_devanagari-500.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''), url('../fonts/poppins-v15-latin-ext_latin_devanagari-700.woff2') format('woff2');
}

$heritage-green: #4e9944;
$heritage-dark-green: #27654c;
$heritage-blue: #009999;
$heritage-dark-blue: #006363;
$heritage-grey: #ececec;

* {
  font-family: "Poppins", sans-serif;
}

body {
  margin-top: 75px;
}

a {
  color: $heritage-green;
  text-decoration: none;

  &:hover {
    color: $heritage-dark-green;
  }
}

footer {
  background-color: #333333;
}

.after-hero {
  padding-top: 3rem;
}

.btn-primary {
  --bs-btn-bg: #{$heritage-green};
  --bs-btn-border-color: none;
  --bs-btn-hover-bg: #{$heritage-blue};
  --bs-btn-active-bg: #{$heritage-blue};
  --bs-btn-disabled-bg: #{$heritage-green};
}

.btn-outline-success {
  --bs-btn-color: #{$heritage-green};
  --bs-btn-border-color: #{$heritage-green};
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active {
  color: white;
  background-color: $heritage-green;
  border-color: $heritage-green;

  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(91%) saturate(0%) hue-rotate(168deg) brightness(110%) contrast(101%);
  }
}

.accordion-button:not(.collapsed) {
  color: #4e9944;
  background-color: #ececec;
}

.bg-light {
  background-color: $heritage-grey !important;

  &-80x20 {
    background: linear-gradient(to top, $heritage-grey 80%, white 20%);
  }
}

.bg-success {
  background-color: $heritage-green !important;
}

.card {
  &-section {
    background: linear-gradient(to bottom, $heritage-grey 55%, white 0%);
  }

  &-icon {
    z-index: 10;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid $heritage-grey;
    background-color: white;
    margin-bottom: -50px;
  }

  &-info {
    background-color: $heritage-dark-green;
    border-radius: 15px;
    padding: 1rem;
    padding-top: 4.5rem;
  }
}

.check-mark {
  filter: invert(51%) sepia(26%) saturate(939%) hue-rotate(66deg) brightness(96%) contrast(87%);
}

.icon-svg {
  width: 45px;
  height: 45px;
}

.walkthrough {
  max-width: 750px;
}

.quote-btn {
  text-shadow: 1px 1px 10px #000000
}

#hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 40% 15%;

  &-box {
    h2 {
      text-shadow: 1px 1px 3px #000000;
    }

    p {
      text-shadow: 1px 1px 3px #000000;
    }
  }

  &-form {
    margin-top: 3rem;
    margin-right: 3rem;
    margin-left: 3rem;
    margin-bottom: -3rem;
  }
}

@media only screen and (max-width: 991px) {
  .after-hero {
    padding-top: 35rem;
  }

  .card {
    &-section {
      background: linear-gradient(to bottom, $heritage-grey 20%, white 0%);
    }
  }

  #hero {
    min-height: 50vh;
    background-position: 30% 15%;

    &-box {
      min-height: 50vh;
    }

    &-form {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      padding-right: 5rem;
      padding-left: 5rem;
      margin-bottom: -35rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .card {
    &-icon {
      margin-bottom: unset;
      margin-right: -50px;
    }

    &-info {
      padding-top: 1rem;
      padding-left: 4.5rem;
    }
  }

  .money-back {
    font-size: medium;
  }

  .w-md-100 {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .after-hero {
    padding-top: 0;
  }

  .position-sm-sticky {
    position: sticky;
  }
}